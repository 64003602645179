export const getIOSVersion = () => {
  if (/iP(hone|od|ad)/.test(navigator.userAgent)) {
    const v = (navigator.userAgent).match(/OS (\d+)_(\d+)_?(\d+)?/);
    return [parseInt(v[1], 10), parseInt(v[2], 10), parseInt(v[3] || 0, 10)];
  }
  return false;
};

// eslint-disable-next-line compat/compat
export const isChromiumBrowser = () => !!navigator.userAgentData && navigator.userAgentData.brands.some(data => data.brand === 'Chromium');
