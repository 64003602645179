let submenuScrollPosition;

function sumenuScroll(restore) {
  const s = document.querySelector('.js-submenu');
  if (!s) { return; }

  if (restore) { s.scrollLeft = submenuScrollPosition; }
  submenuScrollPosition = s.scrollLeft;
}

document.addEventListener('turbolinks:before-cache', () => {
  sumenuScroll();
});

document.addEventListener('turbolinks:render', () => {
  setTimeout(() => sumenuScroll(true));
});

document.addEventListener('turbolinks:load', () => {
  //  Scroll horizontal navigation active item into the visible area of the browser window.
  const activeHorizontalItem = document.querySelector('.js-submenu .active');
  activeHorizontalItem && setTimeout(() => activeHorizontalItem.scrollIntoView(false)); // 'false' parameter is a fix for older browsers (Android 5.0)

  // Add body class if submenu is present
  // Hide submenu if one only one item
  if ($('body > .submenu').length) {
    $('body > .submenu li').length > 1 && $('body').addClass('has_submenu');
    $('body > .submenu li').length === 1 && $('body > .submenu').addClass('hidden');
  }
});
