import '@client-side-validations/client-side-validations';
import '@client-side-validations/simple-form/src';
import 'lib/bu_simple_form_validations';

// Because turbolinks is not attached to window rails.validations don't know how
// to setup event handler so we need to do it ourself
document.addEventListener('turbolinks:load', () => {
  setTimeout(() => { // TODO: Detect the reason why we need to use setTimeout here
    $(window.ClientSideValidations.selectors.forms).enableClientSideValidations();
  }, 0);
});
